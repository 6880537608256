<template lang="pug">
include ../../../configs/template
div
  <h4 class="text-left">{{ $t('report-medical') }}</h4>
    +radio-group('typeMedical', 'items')(row)
  MedCertificates(v-if="typeMedical === '2'")
  MedStatements(v-if="typeMedical === '1'")
</template>
<script>

export default {
  data () {
    return {
      typeMedical: '1',
      items: [
        { id: 1, name_ukr: 'Заяви', name_eng: 'Statements', label: this.$t('report-statements'), value: '1' },
        { id: 2, name_ukr: 'Сертифікати', name_eng: 'Certificates', label: this.$t('report-certificates'), value: '2' }
      ]
    }
  },
  components: {
    MedCertificates: () => import('./MedCertificates.vue'),
    MedStatements: () => import('./MedStatements.vue')
  }
}
</script>
